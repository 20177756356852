<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="12" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">Nombre de nuitées</h4>
          <div v-if="false" class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Bénéfices</span>
            </div>
            <div class="d-flex align-items-center">
              <span
                class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Dépenses</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          ref="testref"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="chartData.chartOptions"
          :series="chartData.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  //   BDropdown,
  //   BDropdownItem,
  //   BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import utilsService from "@/services/utils/utils.service";
import { mapActions, mapState } from "vuex";

const monthsList = utilsService.getListMonths();
const defaultSerieData = [{ data: monthsList.map(e=> 0)}];

const chartData = {
  series: [...defaultSerieData],
  chartOptions: {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    grid: {
      padding: {
        top: -20,
        bottom: -10,
      },
      yaxis: {
        lines: { show: false },
      },
    },
    xaxis: {
      categories: monthsList,
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [$themeColors.danger],
    plotOptions: {
      bar: {
        columnWidth: "17%",
        endingShape: "rounded",
      },
      distributed: true,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
    },
  },
};
export default {
  components: {
    VueApexCharts,
    // BDropdown,
    // BDropdownItem,
    BCard,
    // BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    series: {
      type: Array,
      default: () => {
        return [...defaultSerieData];
      },
    },
  },
  data() {
    return {
      monthsList,
      depenses_salariales: {},
      chartData,
      nombreDeNuitesChartRef:'nombreDeNuitesChartRef'
    };
  },
  async mounted() {},
  watch: {
    series: {
      deep: true, 
      handler(val, old) {
        // console.log(val);
        this.chartData = {
          ...chartData,
          series: val,
        };
        // this.$refs[this.nombreDeNuitesChartRef].setOption(option, {});
      },
    },
  },
  methods: {
  
  },
};
</script>
